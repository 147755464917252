$xs-loader-width: 15px;
$small-loader-width: 20px;
$medium-loader-width: 30px;
$large-loader-width: 40px;

.spinner {
	margin: 0 auto;
	position: relative;

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	&--xs {
		width: $xs-loader-width;
	}

	&--small {
		width: $small-loader-width;
	}

	&--medium {
		width: $medium-loader-width;
	}

	&--large {
		width: $large-loader-width;
	}
}

.spinner > .circular {
	animation: rotate 2s linear infinite;
	bottom: 0;
	height: 100%;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	transform-origin: center center;
	width: 100%;
}

.spinner > .circular > .path {
	animation: dash 1.5s ease-in-out infinite;
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	stroke-linecap: round;
	stroke-width: 5px;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}
