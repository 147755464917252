@import 'react-tippy/dist/tippy.css';
@import 'react-quill/dist/quill.snow.css';
@import 'lightgallery.js/dist/css/lightgallery.css';
@import 'react-lazy-load-image-component/src/effects/blur.css';
@import 'react-quill-with-table/dist/quill.snow.css';
@import 'quill-better-table/dist/quill-better-table.css';
@import 'react-nice-dates/build/style.css';

@import './helpers/colors';
@import './helpers/breakpoints';

@import './components/loader/spinner';

@import './components/layout/pagination';

@import './components/navigation/header';
@import './components/navigation/mobile';
@import './components/navigation/footer';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply m-0;
  @apply p-0;

  -webkit-tap-highlight-color: transparent;
}

*:focus {
  @apply outline-none;
}

html {
  @apply scroll-smooth;
  @apply min-h-full;

  background-color: $color-white;
}

body {
  @apply min-h-full;
  @apply m-0;
  @apply p-0;
  @apply box-border;
  @apply text-base;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.max-mobile-width {
  max-width: calc(100vw - 35px);
}

.min-height {
  min-height: calc(100vh - 64px);
}

.banner {
  background-color: $color-primary;
  @apply py-10;

  p {
    @apply text-2xl;
    @apply font-bold;
    @apply text-white;
    @apply text-center;
    @apply uppercase;
    @apply sm:text-3xl;
  }
}

.novoHealth-banner {
  @apply py-10;
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply justify-center;

  p {
    @apply font-bold;
    @apply text-white;
    @apply text-center;
    @apply uppercase;
    @apply sm:text-4xl;
  }

  .title {
    @apply text-4xl;
    @apply sm:text-5xl;
  }

  .subTitle {
    @apply text-2xl;
    @apply sm:text-3xl;
  }
}

.text {
  &--primary {
    color: $color-primary;
  }
}

.button {
  background-color: $color-primary;
  height: fit-content;
  @apply py-2;
  @apply px-4;
  @apply text-white;
  @apply font-semibold;
  @apply text-sm;
  @apply rounded;
  @apply shadow-lg;

  &--full-width {
    @apply w-full;
    @apply px-0;
  }

  &--text-only {
    @apply bg-transparent;
    @apply shadow-none;

    color: $color-primary;
  }

  &--bold {
    @apply font-bold;
  }
}

.input-field {
  input {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-bottom: 1px;
    padding-top: 1px;
  }
}

.input-mask-field,
.password-field,
.dropdown-field {
  input {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-bottom: 6px;
    padding-top: 6px;
  }
}

.textarea-field {
  textarea {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-bottom: 6px;
    padding-top: 6px;
    resize: none;
  }
}

.rich-text-field {
  #toolbar {
    font-size: 14px;
    margin-top: 5px;
  }
}

.quill {
  .ql-container.ql-snow {
    @apply rounded-b;
    @apply border-gray-300;
  }
}

input[type='radio'] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type='radio'] + label span:hover,
input[type='radio'] + label:hover span {
  transform: scale(1.2);
}

input[type='radio']:checked + label span {
  background-color: $color-primary !important;
  border: 0 !important;
}

.ct-toast {
  border-left: unset !important;

  .ct-toast-success {
    svg {
      path {
        fill: green !important;
      }
    }
  }

  .ct-toast-error {
    svg {
      path {
        fill: red !important;
      }
    }
  }
}

.main {
  min-height: calc(100vh - 64px);
}

.tabs {
  @apply px-5;
  @apply py-3;

  .tabs-list {
    @apply list-none;
    @apply flex;
    @apply overflow-x-auto;
    @apply text-sm;

    li {
      @apply flex;

      border-bottom-width: 3px;
      @apply border-transparent;

      button {
        @apply py-3;
        @apply px-5;
        @apply font-bold;
      }

      &.active {
        @apply border-black;
      }
    }
  }
}

.table {
  margin-bottom: 0 !important;
}

.table td,
.table th {
  border-top: 0 !important;
  vertical-align: middle !important;
}

.table thead th {
  border-bottom: 0 !important;
}

.table-hover tbody tr:hover {
  background-color: rgb(239 243 246) !important;
  cursor: pointer;
}

.race-logo-wrapper {
  display: flex !important;
  height: 150px;
}

.race-logo-image {
  @apply object-contain;
  @apply mx-auto;
  @apply rounded-t-xl;
}

.race-description {
  @apply pt-4;

  p {
    @apply text-sm;
    @apply mb-4;
    @apply sm:text-base;
  }

  h3 {
    @apply text-lg;
    @apply sm:text-xl;
    @apply mt-5;
    @apply mb-2;
  }

  h4 {
    @apply text-base;
    @apply sm:text-lg;
    @apply mt-5;
    @apply mb-2;
    @apply font-bold;
  }

  ul {
    @apply list-disc list-outside;

    li {
      @apply text-sm;
      @apply sm:text-base;

      &:not(:last-child) {
        @apply mb-2;
      }
    }
  }

  a {
    @apply font-semibold;
    @apply text-green-500;
  }
}

/* Summary Table */
.summary {
  @apply mb-5;
  border: 1.5px solid $color-black;
}

.summary thead {
  background-color: $color-black;
  border-bottom: 1.5px solid $color-black;

  tr {
    th {
      &:first-child {
        border-right: 1.5px solid $color-black;
      }
    }
  }
}

.summary tbody {
  tr {
    td {
      &:first-child {
        border-right: 1.5px solid $color-black;
      }
    }
  }

  @apply text-gray-700;
}

.summary th,
.summary td {
  @apply text-center;
  @apply p-3;
}

/* Checkbox */
.checkbox-label {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;

  span.checkbox {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);

    &:first-child {
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      transform: scale(1);
      vertical-align: middle;
      transition: all 0.2s ease;

      svg {
        position: absolute;
        top: 3px;
        left: 2px;
        fill: none;
        stroke: #ffffff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background: #000000;
        display: block;
        transform: scale(0);
        opacity: 1;
        border-radius: 50%;
      }
    }

    &:last-child {
      padding-left: 8px;
    }
  }
}

input[type='checkbox']:checked + .checkbox-label {
  span.checkbox {
    &:first-child {
      background: #000000;
      border-color: #000000;
      animation: wave 0.4s ease;

      svg {
        stroke-dashoffset: 0;
      }

      &:before {
        transform: scale(3.5);
        opacity: 0;
        transition: all 0.6s ease;
      }
    }
  }
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

table.quill-better-table {
  margin-bottom: 20px;
}

.qlbt-cell-line {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

// Date Picker
.nice-dates-popover {
  @apply max-w-xs;
  @apply shadow-lg;
  @apply rounded-xl;
  @apply border;
  @apply border-gray-300;
}

.nice-dates-day:before {
  background-color: $color-primary;
}

.nice-dates-day:after {
  border: 2px solid $color-primary;
}

// Custom Styles
.novoHealth-ol {
  counter-reset: list;
}

.novoHealth-ol > li {
  list-style: none;
  font-size: 15px;
  margin-bottom: 6px;
}

.novoHealth-ol > li:before {
  content: counter(list, lower-roman) ') ';
  counter-increment: list;
  font-weight: bold;
}
