footer {
	@apply py-8;
	@apply bg-black;
	@apply text-white;

	h1,
	h2 {
		@apply font-semibold;
		@apply mb-2;
	}

	h1 {
		@apply text-2xl;
	}

	h2 {
		@apply text-lg;
	}

	p {
		@apply text-base;
		@apply font-light;
		@apply leading-relaxed;
	}

	a {
		@apply text-sm;
		@apply font-light;
	}
}
