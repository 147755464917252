.mobile {
	@apply absolute;
	@apply top-0;
	@apply left-0;
	@apply w-screen;
	@apply h-screen;
	@apply bg-white;
	@apply z-20;

	box-shadow: 0 6px 25px -7px rgb(0 0 0 / 32%);
	transition: left 0.5s;

	&--open {
		left: 0;
	}

	&--close {
		left: -100%;
	}

	ul {
		li:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}
}
