header {
	.logo {
		height: 45px;
	}

	.hamburger-menu {
		cursor: pointer;
		height: 18px;
		position: relative;
		transform: rotate(0deg);
		transition: 0.5s ease-in-out;
		width: 25px;

		span {
			background: $color-black;
			border-radius: 9px;
			display: block;
			height: 2px;
			left: 0;
			opacity: 1;
			position: absolute;
			transform: rotate(0deg);
			transition: 0.25s ease-in-out;
			width: 100%;
		}

		span:nth-child(1) {
			top: 0;
		}

		span:nth-child(2),
		span:nth-child(3) {
			top: 9px;
		}

		span:nth-child(4) {
			top: 18px;
		}

		&--open span:nth-child(1) {
			left: 50%;
			top: 9px;
			width: 0%;
		}

		&--open span:nth-child(2) {
			transform: rotate(45deg);
		}

		&--open span:nth-child(3) {
			transform: rotate(-45deg);
		}

		&--open span:nth-child(4) {
			left: 50%;
			top: 9px;
			width: 0%;
		}
	}
}
