$color-grey: rgba(#9b9b9b, 0.3);

.pagination {
	align-items: center;
	display: flex;
	font-size: 18px;
	font-weight: 700;
	list-style: none;
	margin-bottom: 10px;
	margin-top: 10px;
	padding: 0;
	user-select: none;

	.page-item {
		align-items: center;
		color: $color-primary;
		cursor: pointer;
		display: flex;
		font-size: 14px;
		justify-content: center;
		margin: 0 2px;
		min-height: 25px;
		min-width: 25px;
		transition: all 0.3s;

		&:hover {
			background: $color-grey;
			color: $color-primary;
		}

		&.active {
			background: $color-primary;
			color: $color-white;
			padding: 0;
		}

		&.arrow-icon {
			font-size: 35px;
			position: relative;
			transform: translateY(-2px);
			transition: all 0.3s;
			z-index: 1;

			&::after {
				content: '';
				height: 25px;
				left: 50%;
				position: absolute;
				top: 56%;
				transform: translate(-50%, -50%);
				width: 25px;
				z-index: -1;
			}

			&:hover {
				background: transparent;
				color: $color-primary;

				&::after {
					background: $color-grey;
				}
			}

			&.disabled {
				color: $color-grey;
				cursor: not-allowed;
				pointer-events: none;

				&:hover {
					background: transparent;
				}
			}
		}
	}
}
